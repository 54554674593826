<template>
  <page-skeleton theme="light" :steps="false" :products="false">
    <b-container>
      <h1 class="text-uppercase mt-5 my-3 title-text">
        {{ $t('careers.jobs.title') }}
      </h1>
      <div>No current job openings</div>
      <job-opening class="my-4" v-if="0">
        <template #header>
          Office Manager
        </template>
        <template #location>
          Berlin, Germany
        </template>
        <template #type>
          Onsite
        </template>
        <template #hours>
          30-40 hours / week
        </template>
        <template #qualifications>
          <ul class="i-text-small">
            <li>Fließende Deutsch und Englischkenntnisse in Wort und Schrift</li>
            <li>Sicherer Umgang mit Email- und Buchhaltungsprogrammen (Lexoffice)</li>
            <li>Kenntnisse in Word und Excel</li>
            <li>Kenntnisse im Bereich der vorbereitenden Buchhaltung</li>
            <li>Sorgfältiger Umgang mit Dokumenten und Ordnerstrukturen</li>
            <li>Selbständiges Arbeiten und Eigeninitiative</li>
          </ul>
        </template>
        <template #sub-qualifications>
          ---
        </template>
        <template #description>
          Für die Unterstützung im Tagesgeschäft suchen wir eine/n Office Manager/in (m/w/d).
          Das Hauptaufgabenfeld stellt die allgemeine Unterstützung der Büroorganisation in Berlin Schöneberg dar. Das
          beinhaltet Aufgaben wie vorbereitende Buchhaltung, Warenbestellung und Einkauf, Warenentsendung,
          Lagerorganisation sowie Verantwortung dafür dass unser Büro ordentlich und gemütlich ist.
        </template>
        <template #responsibilities>
          <ul class="i-text-small">
            <li>Organisation des Büros
              <ul>
                <li>Administrative Tätigkeiten</li>
                <li>Prozessoptimierung bei Abläufen im Büroalltag</li>
                <li>Teameventorganisation</li>
              </ul>
            </li>
            <li>Vorbereitende Aufgaben der Buchhaltung
              <ul>
                <li>Erstellung von Verkaufsbelegen: Angebote, Auftragsbestätigungen, Lieferscheine, Rechnungen</li>
                <li>Sachliche und inhaltliche Rechnungsprüfung, -erfassung und Kontierung</li>
                <li>Reisekostenabrechnung</li>
                <li>Inventur</li>
              </ul>
            </li>
            <li>Vorbereitende Aufgaben der Logistik
              <ul>
                <li>Warenbestellung und Einkauf</li>
                <li>Wareneingang und Lagerorganisation</li>
                <li>Vorbereitung des Warenausgangs</li>
              </ul>
            </li>
          </ul>
        </template>
      </job-opening>
<!--      <h1 class="text-uppercase mt-5 my-3 title-text">-->
<!--        {{ $t('careers.research.current.title') }}-->
<!--      </h1>-->
      <h1 class="text-uppercase mt-5 my-3 title-text">
        {{ $t('careers.research.potential.title') }}
      </h1>
      <ul class="my-4">
        <li class="mb-2">Evaluate Accuracy of Measurement Station X. Degrees of freedom X,Y,Z</li>
        <li class="mb-2">Six-Sigma and other Quality Parameter in the Tubular Glass Manufacturing Industry</li>
        <li class="mb-2">Analyse und Quantifizierung auftretender Messfehler und deren Fehlerfortpflanzung und Erstellung eines Workflowsheets für die Dokumentation einer Messeinheit</li>
        <li class="mb-2">Konstruktion einer Aushebevorrichtung für Nest-Magazinsysteme</li>
        <li class="mb-2">Konstruktion und Auslegung eines Schaufelradaushebers</li>
        <li class="mb-2">Messstationsprüfstand entwickeln, konstruieren und bauen</li>
        <li class="mb-2">Experimentelle Untersuchung der Identifizierbarkeit von kosmetischen Fehlern in Glaskörpern in Abhängigkeit der Art und des Einstrahlwinkels des Beleuchtungssystems</li>
        <li class="mb-2">Positionierungsgenauigkeit der Förderlinie im Bezug auf  Takeoff/Landing Verhalten von Glasskörpern in Produktionsprozessen</li>
        <li class="mb-2">Erstellen eines Prozesses zur Messmittelfähigkeitsanalyse optischer Messsysteme inklusive Definition eines Betriebshandbuchprotokolls</li>
        <li class="mb-2">Entwicklung einer Innenbeleuchtungseinheit für Glaskörper</li>
        <li class="mb-2">Analyse strukturierte Beleuchtungen auf die Sichtbarkeit von Ungleichförmigkeiten des Glaskörpers</li>
        <li class="mb-2">Polarisation und Flüssiglinsen bei Messprüfungung in der 100% Kontrolle und Kosmetischrn Fehlern</li>
      </ul>
    </b-container>
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import JobOpening from "@/components/careers/JobOpening"

export default {
  components: {
    PageSkeleton,
    JobOpening
  },
  metaInfo() {
    return {
      title: this.$t('careers.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('careers.meta.description')}
      ]
    }
  }
}

</script>

<style lang="sass" scoped>
.title-text
  //font-size: 60px

</style>