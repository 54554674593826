<template>
  <b-card>
    <div class="i-card-header i-font-text-bold">
      <slot name="header"></slot>
    </div>
    <b-row class="i-text-small mt-1">
      <b-col cols="auto">
        <font-awesome-icon icon="map-marker" class="mr-2"/>
        <slot name="location"></slot>
      </b-col>
      <b-col cols="auto">
        <font-awesome-icon icon="desktop" class="mr-2"/>
        <slot name="type"></slot>
      </b-col>
      <b-col>
        <font-awesome-icon icon="briefcase" class="mr-2"/>
        <slot name="hours"></slot>
      </b-col>
    </b-row>
    <div class="i-card-subheader i-font-text-bold mt-3">Qualifications</div>
    <div>
      <slot name="qualifications"></slot>
    </div>
    <b-row v-if="!expanded" class="i-text-small justify-content-center">
      <b-col class="text-center">
        <b-link @click="expanded = true">
          Expand
        </b-link>
      </b-col>
    </b-row>
    <b-row v-if="expanded">
      <b-col cols="12">
        <div class="i-card-subheader i-font-text-bold mt-3">Nice to Have Qualifications</div>
        <div class="i-text-small">
          <slot name="sub-qualifications"></slot>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="i-card-subheader i-font-text-bold mt-3">Job Description</div>
        <div class="i-text-small">
          <slot name="description"></slot>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="i-card-subheader i-font-text-bold mt-3">Responsibilities</div>
        <div class="i-text-small">
          <slot name="responsibilities"></slot>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="i-card-subheader i-font-text-bold mt-3">Who we are</div>
        <div class="i-text-small">
          Isotronic develops soft- and hardware used in the manufacturing of vials, syringes, ampoules and more in the
          pharmaceutical glass industry. We built VialChecker, a quality control software that users interact with on
          the production facility floor. Another product, Central, is a cloud based user portal where users can
          monitor stats of their entire production facility, manage orders, generate production reports and more.
        </div>
      </b-col>
      <b-col cols="12">
        <div class="i-card-subheader i-font-text-bold mt-3">What is it like working at Isotronic</div>
        <div class="i-text-small">
          Isotronic in its current form was founded 5 years ago and carried on the work of a veteran in the field, and
          therefore has the feeling of a startup but with the security of a well established business.
          Hours are flexible and we accomodate the schedules of all our employees to ensure healthy work life balance.
          We are flexible with home office and coming to the office.
          Thanks and we are looking forward to hearing from you!
          Please send applications to mail@isotronic.de
        </div>
      </b-col>
    </b-row>
    <b-row v-if="expanded" class="i-text-small justify-content-center mt-3">
      <b-col class="text-center">
        <b-link @click="expanded = false">
          Collapse
        </b-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      expanded: false
    }
  }
}

</script>